<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      {{ stateLabels[0] }}
      <div class="subHeadTitle">{{ stateLabels[1] }}</div>
    </div>
    <Panel v-if="PERMISIONS.institution" :options="panelData" />
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center">
        <span style="font-size: 4rem">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/allPermMixin.js";
import { INSTITUTIONS_PAGINATED } from "../api.js";
import Panel from "@/components/InstitutionsPanel";

export default {
  name: "Institution",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin],
  components: {
    Panel,
  },
  data() {
    return {
      panelData: {action: 'edit', data: {}},
    };
  },
  methods: {},
  computed: {
    stateLabels() {
      return [" / Administrare", "Profil instituție"];
    },
  },
  created() {
    this.initAllPermisions();

    if (!this.PERMISIONS.institution) {
      return;
    }
  },
  mounted() {
    this.setLoad(true);
    const institution = this.getUserInstitution(true);
    INSTITUTIONS_PAGINATED(1, 1, null, `eq=id,${institution.id}`)
      .then((res) => {
        this.panelData = { action: "edit", data: res.data.result[0] };
        this.setLoad(false)
      })
      .catch(() => {});
  },
};
</script>
